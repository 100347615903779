import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface JiraTicketAssignee {
  name: string;
  emailAddress: string;
  displayName: string;
}

export interface JiraLinkedIssue {
  inwardIssue?: JiraTicket;
  outwardIssue?: JiraTicket;
  linkTypeName: string;
}

export interface JiraTicket {
  summary: string;
  issueType: string;
  description?: string;
  id?: string;
  key?: string;
  status?: string;
  assignee?: JiraTicketAssignee;
  reporter?: string;
  projectKey?: string;
  labels?: string[];
  issuelinks?: JiraLinkedIssue[];
  customfield_11519?: string[]; // planning intervals
}

export interface SubComponentJIRATicket {
  subcomponentName: string;
  ticket: JiraTicket | undefined;
  processingTicketStatus: ProcessingTicketStatus;
  processingInProgress: boolean;
}

export interface JiraState {
  parentJiraTicket: JiraTicket | undefined;
  subComponentJIRATickets: Record<string, SubComponentJIRATicket>;
}

export type ProcessingTicketStatus = 'NOT_CREATED' | 'CREATED' | 'ERROR_CREATING' | 'LINKED' | 'ERROR_LINKING' | 'DONE';

export const getSubComponentJIRATicketInitialData = (subcomponentName: string) => {
  return {
    subcomponentName,
    processingTicketStatus: 'NOT_CREATED',
    processingInProgress: false,
  } as SubComponentJIRATicket;
};

export const getJiraStateInitialData = () => {
  return { subComponentJIRATickets: {} } as JiraState;
};

const jiraSlice = createSlice({
  name: 'JIRA',
  initialState: getJiraStateInitialData(),
  reducers: {
    updateParentJiraTicket: (state, action: PayloadAction<{ parentJiraTicket: JiraTicket | undefined }>) => {
      return {
        ...state,
        parentJiraTicket: action.payload.parentJiraTicket,
        subComponentJIRATickets: {},
      };
    },
    updateJiraTicket: (
      state,
      action: PayloadAction<{ subcomponentName: string; jiraPayload: SubComponentJIRATicket }>,
    ) => {
      const newState = {
        ...state,
        subComponentJIRATickets: {
          ...state.subComponentJIRATickets,
        },
      };
      newState.subComponentJIRATickets[action.payload.subcomponentName] = action.payload.jiraPayload;
      return newState;
    },
  },
});

export const { updateJiraTicket, updateParentJiraTicket } = jiraSlice.actions;
export default jiraSlice.reducer;
