import React, { useState } from 'react';

import { Avatar, Box, Button, Card, CardContent, CircularProgress, TextField, Typography } from '@mui/material';

import JiraService, { JiraProject } from '../../services/JiraService';

export const JiraProjectsView: React.FC = () => {
  const [keys, setKeys] = useState<string>(
    'GC,GCBWM,GCBP,GCIT,GCDM,GCDY,GCDH,GCFP,GCRM,GCLM,GCLSG,GCRMC,GCRJ,GCSPTN,GCAVG,GCWE',
  );
  const [projects, setProjects] = useState<JiraProject[] | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleLoadProjects = async () => {
    setLoading(true);
    setError(null);
    setProjects(null);

    try {
      const response = await JiraService.getProjects(keys);
      setProjects(response.data);
    } catch {
      setError('Failed to load projects. Please check your API and keys.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ width: '100%', padding: 2 }}>
      <Typography variant="h5" gutterBottom>
        JIRA Projects Viewer
      </Typography>
      <TextField
        label="Project Keys (comma-separated)"
        variant="outlined"
        fullWidth
        value={keys}
        onChange={(e) => setKeys(e.target.value)}
        margin="normal"
      />
      <Button variant="contained" color="primary" onClick={handleLoadProjects} disabled={loading} sx={{ marginTop: 2 }}>
        {loading ? <CircularProgress size={24} color="inherit" /> : 'Load Projects'}
      </Button>

      {error && (
        <Typography variant="body2" color="error" sx={{ marginTop: 2 }}>
          {error}
        </Typography>
      )}

      {projects && (
        <Box sx={{ marginTop: 4 }}>
          <Typography variant="h6" gutterBottom>
            Loaded Projects
          </Typography>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: 'repeat(2, 1fr)',
              gap: 2,
            }}
          >
            {projects.map((project) => (
              <Card key={project.id}>
                <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
                  <Avatar
                    src={project.avatarUrls['32x32']}
                    alt={project.name}
                    sx={{ marginRight: 2, width: 48, height: 48 }}
                  />
                  <Box>
                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                      {project.name}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      ({project.key})
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
};
