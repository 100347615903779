import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useParentJiraTicket, useSubComponentJiraTicketState } from '../store/jiraStore/jiraSelectors';
import { JiraTicket, ProcessingTicketStatus, updateJiraTicket } from '../store/jiraStore/jiraReducer';
import { assertNotNullOrUndefined, isNotNullOrUndefined } from '../utils/utils';
import { Link2JiraTicketsRequest } from '../types/jira.types';
import JiraService from '../services/JiraService';

enum NewTicketAction {
  NO_ACTION = 'NO_ACTION',
  CREATE = 'CREATE',
  LINK = 'LINK',
  COMPLETED = 'COMPLETED',
}

const getNextAction = (currentStatus: ProcessingTicketStatus, jiraTicket: JiraTicket): NewTicketAction => {
  switch (currentStatus) {
    case 'NOT_CREATED':
    case 'ERROR_CREATING': {
      if (isNotNullOrUndefined(jiraTicket.key)) {
        return NewTicketAction.LINK;
      }
      return NewTicketAction.CREATE;
    }
    case 'CREATED':
    case 'ERROR_LINKING': {
      return NewTicketAction.LINK;
    }
    case 'LINKED':
    case 'DONE': {
      return NewTicketAction.COMPLETED;
    }
    default: {
      return NewTicketAction.NO_ACTION;
    }
  }
};

const delay = (milliseconds: number) => {
  const start = Date.now();
  while (Date.now() - start < milliseconds) {
    // Busy-wait loop
  }
};

const createJiraTicketDryRun = async (componentName: string, jiraTicket: JiraTicket) => {
  // eslint-disable-next-line no-console
  console.log(`${componentName}: Creating JIRA ticket (fake)`, jiraTicket);
  delay(100);
  const issueKey = `${jiraTicket.projectKey}-0000`;
  // eslint-disable-next-line no-console
  console.log(`${componentName}: Ticket created successfully: ${issueKey} (fake)`);
  return {
    key: issueKey,
  } as JiraTicket;
};

const createJiraTicket = async (componentName: string, jiraTicket: JiraTicket) => {
  // eslint-disable-next-line no-console
  console.log(`${componentName}: Creating JIRA ticket`, jiraTicket);
  const createdTicket = await JiraService.createIssue(jiraTicket);
  // eslint-disable-next-line no-console
  console.log(`${componentName}: Ticket created successfully: ${createdTicket}`);
  return {
    ...jiraTicket,
    key: createdTicket,
  } as JiraTicket;
};

const linkJiraTicketDryRun = async (componentName: string, linkData: Link2JiraTicketsRequest) => {
  // eslint-disable-next-line no-console
  console.log(`${componentName}: Linking JIRA ticket (fake)`, linkData);
  delay(500);
  // eslint-disable-next-line no-console
  console.log(`${componentName}: Ticket linked successfully (fake)`);
};

const linkJiraTicket = async (componentName: string, linkData: Link2JiraTicketsRequest) => {
  // eslint-disable-next-line no-console
  console.log(`${componentName}: Linking JIRA ticket`, linkData);
  await JiraService.linkIssues(linkData);
  // eslint-disable-next-line no-console
  console.log(`${componentName}: Ticket linked successfully.`);
};

export const useJiraProcessTicket = (subcomponentName: string, isDryRun: boolean, jiraTicket: JiraTicket) => {
  const dispatch = useDispatch();
  const subComponentTicket = useSubComponentJiraTicketState(subcomponentName);
  const parentTicket = useParentJiraTicket();

  useEffect(() => {
    const executeNextAction = async () => {
      assertNotNullOrUndefined(subComponentTicket);
      const ticket = {
        ...subComponentTicket,
      };

      switch (getNextAction(ticket.processingTicketStatus, jiraTicket)) {
        case NewTicketAction.CREATE: {
          try {
            const createdTicket = isDryRun
              ? await createJiraTicketDryRun(subcomponentName, jiraTicket)
              : await createJiraTicket(subcomponentName, jiraTicket);
            ticket.processingTicketStatus = 'CREATED';
            ticket.ticket = createdTicket;
          } catch (error) {
            // eslint-disable-next-line no-console
            console.log(`${subcomponentName}: Error creating ticket`, error);
            ticket.processingTicketStatus = 'ERROR_CREATING';
            ticket.processingInProgress = false;
          }
          break;
        }
        case NewTicketAction.LINK: {
          try {
            const linkData: Link2JiraTicketsRequest = {
              type: {
                name: 'Agile Hive Link',
              },
              inwardIssue: {
                key: subComponentTicket.ticket?.key ?? '',
              },
              outwardIssue: {
                key: parentTicket?.key ?? '',
              },
            };
            isDryRun
              ? await linkJiraTicketDryRun(subcomponentName, linkData)
              : await linkJiraTicket(subcomponentName, linkData);
            ticket.processingTicketStatus = 'LINKED';
          } catch (error) {
            // eslint-disable-next-line no-console
            console.log(`${subcomponentName}: Error creating ticket`, error);
            ticket.processingTicketStatus = 'ERROR_LINKING';
            ticket.processingInProgress = false;
          }
          break;
        }
        case NewTicketAction.COMPLETED: {
          ticket.processingTicketStatus = 'DONE';
          ticket.processingInProgress = false;
          break;
        }
        default: {
          ticket.processingInProgress = false;
          break;
        }
      }
      setTimeout(() => dispatch(updateJiraTicket({ subcomponentName, jiraPayload: ticket })), 100);
    };

    if (isNotNullOrUndefined(subComponentTicket) && subComponentTicket.processingInProgress) {
      executeNextAction();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subComponentTicket]);

  return subComponentTicket;
};
