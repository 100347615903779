import React, { useEffect, useState } from 'react';

import {
  Box,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from '@mui/material';
import { useNavigate, useSearch } from '@tanstack/react-router';

import { useCLMComponentsConfig } from '../hooks/useCLMComponentsConfig';
import { useCLMSubcomponentHealth } from '../hooks/useCLMSubcomponentHealth';
import { useCLMSubcomponentInfo } from '../hooks/useCLMSubcomponentInfo';
import { CLMComponent, CLMComponentsConfig, CLMSubcomponentInfo, HealthStatus } from '../types';
import { getComponentMapByHealthStatus, isNotNullOrUndefined, isNullOrUndefined } from '../utils/utils';
import { BuildTimeFilterModal } from './BuildTimeFilterModal';

type Props = {
  setFilteredComponents: React.Dispatch<React.SetStateAction<CLMComponent[]>>;
};

const getComponentsMapByBuildTime = (
  components: CLMComponent[],
  subcomponentInfo: Record<string, Record<string, CLMSubcomponentInfo | undefined>>,
) => {
  const buildTime: Record<string, string | undefined> = {};

  components.forEach((component) => {
    Object.values(component.subComponents).forEach((subcomponent) => {
      const subcomponentInfoElement = subcomponentInfo[component.name];
      if (isNotNullOrUndefined(subcomponentInfoElement)) {
        const time = subcomponentInfoElement[subcomponent.name]?.build.time;
        buildTime[subcomponent.name] = isNotNullOrUndefined(time) ? new Date(time).toISOString() : undefined;
      } else {
        buildTime[subcomponent.name] = undefined;
      }
    });
  });
  return buildTime;
};

const getRelevantComponents = (clmComponentsConfig: CLMComponentsConfig | undefined) =>
  isNullOrUndefined(clmComponentsConfig)
    ? undefined
    : Object.values(clmComponentsConfig.components).sort((a, b) => a.name.localeCompare(b.name));

export const Filter: React.FC<Props> = ({ setFilteredComponents }) => {
  const searchParams = useSearch({ from: '/info' });
  const navigate = useNavigate({ from: '/info' });
  const [componentsByHealthStatus, setComponentsByHealthStatus] = useState<Record<string, HealthStatus> | undefined>(
    undefined,
  );
  const [componentsByBuildTime, setComponentsByBuildTime] = useState<Record<string, string | undefined> | undefined>(
    undefined,
  );
  const { data: clmComponentsConfig } = useCLMComponentsConfig();
  const { data: subcomponentHealth } = useCLMSubcomponentHealth(
    clmComponentsConfig?.environmentName,
    clmComponentsConfig?.components,
  );
  const { data: subcomponentInfo } = useCLMSubcomponentInfo(
    clmComponentsConfig?.environmentName,
    clmComponentsConfig?.components,
  );

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [date, setDate] = useState<any>();
  const [range, setRange] = useState<string>('older');

  useEffect(() => {
    if (isNotNullOrUndefined(clmComponentsConfig) && isNotNullOrUndefined(subcomponentHealth)) {
      const componentsToDisplay = getRelevantComponents(clmComponentsConfig);
      setComponentsByHealthStatus(getComponentMapByHealthStatus(componentsToDisplay, subcomponentHealth));
    } else {
      setComponentsByHealthStatus(undefined);
    }
  }, [clmComponentsConfig, subcomponentHealth]);

  useEffect(() => {
    const componentsToDisplay = getRelevantComponents(clmComponentsConfig);
    if (isNotNullOrUndefined(componentsToDisplay) && isNotNullOrUndefined(subcomponentInfo)) {
      setComponentsByBuildTime(getComponentsMapByBuildTime(componentsToDisplay, subcomponentInfo));
    } else {
      setComponentsByBuildTime(undefined);
    }
  }, [clmComponentsConfig, subcomponentInfo]);

  useEffect(() => {
    let filtered = getRelevantComponents(clmComponentsConfig);
    if (isNotNullOrUndefined(clmComponentsConfig) && isNotNullOrUndefined(filtered)) {
      const teamsParam = searchParams.teams;
      const statusParam = searchParams.status;
      const buildParam = searchParams.build;

      if (isNotNullOrUndefined(teamsParam)) {
        const teams = new Set(
          clmComponentsConfig.clmTeams
            .filter((team) => teamsParam.split(',').includes(team.shortName))
            .map((team) => team.key),
        );
        filtered = filtered.filter((component) => teams.has(component.team));
      }

      if (isNotNullOrUndefined(statusParam) && isNotNullOrUndefined(componentsByHealthStatus)) {
        const statusToFilter = statusParam.split(',');

        filtered = filtered.filter((component) => {
          return Object.values(component.subComponents).some((subcomponent) => {
            return statusToFilter.includes(componentsByHealthStatus[subcomponent.name]);
          });
        });
      }

      if (isNotNullOrUndefined(buildParam) && isNotNullOrUndefined(componentsByBuildTime)) {
        const date = new Date(buildParam).toISOString();

        filtered = filtered.filter((component) => {
          return Object.values(component.subComponents).some((subcomponent) => {
            const buildTimeElement = componentsByBuildTime[subcomponent.name];
            if (isNullOrUndefined(buildTimeElement)) {
              return 0;
            } else if (range === 'older') {
              return buildTimeElement < date;
            } else if (range === 'newer') {
              return buildTimeElement > date;
            }
          });
        });
      }

      setFilteredComponents(filtered);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, subcomponentInfo, subcomponentHealth]);

  return (
    <Box
      sx={{
        display: 'flex',
        gap: '1rem',
      }}
    >
      <FormControl>
        <InputLabel id="teams-filter-label" size="small">
          Filter Teams
        </InputLabel>
        <Select
          sx={{ minWidth: '200px' }}
          labelId="teams-filter-label"
          multiple
          size="small"
          value={searchParams.teams?.split(',') || []}
          input={<OutlinedInput label="Filter Teams" />}
          renderValue={(selected) => selected.join(', ')}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 48 * 4.5 + 8,
                width: 250,
              },
            },
          }}
          onChange={async (e) => {
            const value = e.target.value as string[];
            await navigate({
              search: (prev) => ({ ...prev, teams: value.length > 0 ? value.join(',') : undefined }),
            });
          }}
          data-testid="team-filter-select"
        >
          {clmComponentsConfig &&
            clmComponentsConfig.clmTeams
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((team) => (
                <MenuItem key={team.shortName} value={team.shortName}>
                  <Checkbox checked={searchParams.teams?.split(',').includes(team.shortName) || false} />
                  <ListItemText primary={`${team.name} (${team.shortName})`} />
                </MenuItem>
              ))}
        </Select>
      </FormControl>
      <FormControl>
        <InputLabel id="status-filter-label" size="small">
          Filter Status
        </InputLabel>
        <Select
          sx={{ minWidth: '200px' }}
          labelId="status-filter-label"
          multiple
          size="small"
          value={searchParams.status?.split(',') || []}
          input={<OutlinedInput label="Filter Status" />}
          renderValue={(selected) => selected.join(', ')}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 48 * 4.5 + 8,
                width: 250,
              },
            },
          }}
          onChange={async (e) => {
            const value = e.target.value as string[];
            await navigate({
              search: (prev) => ({ ...prev, status: value.length > 0 ? value.join(',') : undefined }),
            });
          }}
          data-testid="status-filter-select"
        >
          {['UP', 'DOWN', 'UNKNOWN', 'OUT_OF_SERVICE', 'NOT_DEPLOYED']
            .sort((a, b) => a.localeCompare(b))
            .map((status) => (
              <MenuItem key={status} value={status} data-testid={status}>
                <Checkbox checked={searchParams.status?.split(',').includes(status) || false} />
                <ListItemText primary={status} />
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <Box>
        <Button onClick={() => setIsModalOpen(true)} data-testid="open-build-time-modal">
          Change Build Time Filter
        </Button>
        {isModalOpen ? (
          <BuildTimeFilterModal
            onClose={() => setIsModalOpen(false)}
            date={date}
            range={range}
            setRange={setRange}
            setDate={setDate}
          />
        ) : null}
      </Box>
    </Box>
  );
};
