import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid2,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';

import { JiraSubComponentTicketTable, TableInformation } from './JiraSubComponentTicketTable';
import { CLMComponentsConfig, CLMTeam } from '../../types';
import { isNotNullOrUndefined, isStringBlank, isStringNotBlank } from '../../utils/utils';
import { useJiraTicketState, useParentJiraTicket } from '../../store/jiraStore/jiraSelectors';
import { updateJiraTicket } from '../../store/jiraStore/jiraReducer';
import { PlanningIntervalDropdown } from '../../components/jira/PlanningIntervalDropdown';

interface Props {
  clmComponentConfig: CLMComponentsConfig | undefined;
}

export const JiraChildTicketsView: React.FC<Props> = ({ clmComponentConfig }) => {
  const dispatch = useDispatch();
  const parentJira = useParentJiraTicket();
  const [username, setUsername] = useState<string>('');
  const [newTicketTitle, setNewTicketTitle] = useState<string>(parentJira?.summary ?? '');
  const [description, setDescription] = useState<string>('');
  const [isCreateAllInProgress, setIsCreateAllInProgress] = useState(false);
  const [someInProgress, setSomeInProgress] = useState(false);
  const allJiraTickets = useJiraTicketState();
  const [useSubComponents, setUseSubComponents] = useState<boolean>(true);
  const [includeFrontend, setIncludeFrontend] = useState<boolean>(true);
  const [hasFrontendAndBackend, setHasFrontendAndBackend] = useState<boolean>(false);
  const [includeMgmt, setIncludeMgmt] = useState<boolean>(true);
  const [includeLibraries, setIncludeLibraries] = useState<boolean>(true);
  const [isDryRun, setIsDryRun] = useState<boolean>(true);
  const [ticketLabel, setTicketLabel] = useState('tech20');
  const [selectedPlanningIntervals, setSelectedPlanningIntervals] = useState<string[]>([]);
  const [selectedRows, setSelectedRows] = useState<Set<number>>(new Set());

  const teams: Record<string, CLMTeam> | undefined = clmComponentConfig?.clmTeams.reduce(
    (acc: Record<string, CLMTeam>, team) => {
      acc[team.key] = team;
      return acc;
    },
    {},
  );

  const getSubcomponentInfo = (componentName: string, team: CLMTeam) => {
    return {
      componentName: componentName,
      team: team,
    } as TableInformation;
  };

  const getTableInformation = () => {
    const subcomponents: TableInformation[] = [];

    if (isNotNullOrUndefined(clmComponentConfig) && isNotNullOrUndefined(teams)) {
      if (useSubComponents) {
        Object.values(clmComponentConfig.components).forEach((component) => {
          Object.entries(component.subComponents).forEach(([subComponentKey, subcomponent]) => {
            if (includeFrontend && subComponentKey.startsWith('frontend')) {
              subcomponents.push(getSubcomponentInfo(subcomponent.name, teams[component.team]));
            }
            if (includeMgmt && subComponentKey.startsWith('mgmt')) {
              subcomponents.push(getSubcomponentInfo(subcomponent.name, teams[component.team]));
            }
          });
        });
      } else {
        Object.values(clmComponentConfig.components).forEach((component) => {
          let isFrontend = false;
          let isMgmt = false;
          Object.entries(component.subComponents).forEach(([subComponentKey, _subcomponent]) => {
            if (includeFrontend && subComponentKey.startsWith('frontend')) {
              isFrontend = true;
            }
            if (includeMgmt && subComponentKey.startsWith('mgmt')) {
              isMgmt = true;
            }
          });
          if (hasFrontendAndBackend) {
            if (isFrontend && isMgmt) {
              subcomponents.push(getSubcomponentInfo(component.name, teams[component.team]));
            }
          } else if (isFrontend || isMgmt) {
            subcomponents.push(getSubcomponentInfo(component.name, teams[component.team]));
          }
        });
      }

      if (includeLibraries) {
        Object.values(clmComponentConfig.components).forEach((component) => {
          Object.entries(component.subComponents).forEach(([subComponentKey, subcomponent]) => {
            if (includeLibraries && subComponentKey.startsWith('library')) {
              subcomponents.push(getSubcomponentInfo(subcomponent.name, teams[component.team]));
            }
          });
        });
      }
    }

    subcomponents.sort((a, b) => a.componentName.localeCompare(b.componentName));

    return subcomponents;
  };
  const tableInfo = getTableInformation();

  const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value);
  };

  const handleNewTicketTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewTicketTitle(event.target.value);
  };

  const handleDropdownChange = (selectedValues: string[]) => {
    setSelectedPlanningIntervals(selectedValues);
  };

  const handleUseSubComponentsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUseSubComponents(event.target.checked);
  };

  const handleIncludeFrontendChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIncludeFrontend(event.target.checked);
  };

  const handleIncludeMgmtChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIncludeMgmt(event.target.checked);
  };

  const handleHasFrontendAndBackendChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHasFrontendAndBackend(event.target.checked);
    if (event.target.checked) {
      setUseSubComponents(false);
      setIncludeMgmt(true);
      setIncludeFrontend(true);
      setIncludeLibraries(false);
    }
  };

  const handleIncludeLibrariesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIncludeLibraries(event.target.checked);
  };

  const handleIsDryRunChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsDryRun(event.target.checked);
  };

  const handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDescription(event.target.value);
  };

  const handleTicketLabelChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTicketLabel(event.target.value);
  };

  const handleMatchTicketsClick = () => {
    // loop through linked JIRA tickets in parent
    if (
      isNotNullOrUndefined(clmComponentConfig) &&
      isNotNullOrUndefined(parentJira) &&
      isNotNullOrUndefined(parentJira.issuelinks) &&
      isStringNotBlank(newTicketTitle)
    ) {
      parentJira.issuelinks.forEach((linkedTicket) => {
        const ticket = linkedTicket.outwardIssue ?? linkedTicket.inwardIssue;
        // compare to expected summary title
        if (isNotNullOrUndefined(ticket) && ticket.issueType === 'Story' && ticket.summary.endsWith(newTicketTitle)) {
          let matchFoundName = '';
          Object.values(clmComponentConfig.components).forEach((component) => {
            if (ticket.summary === `${component.name}: ${newTicketTitle}`) {
              matchFoundName = component.name;
            } else {
              Object.entries(component.subComponents).forEach(([_subComponentKey, subcomponent]) => {
                if (ticket.summary === `${subcomponent.name}: ${newTicketTitle}`) {
                  matchFoundName = subcomponent.name;
                }
              });
            }
          });

          if (isStringNotBlank(matchFoundName)) {
            setTimeout(
              () =>
                dispatch(
                  updateJiraTicket({
                    subcomponentName: matchFoundName,
                    jiraPayload: {
                      subcomponentName: matchFoundName,
                      processingInProgress: false,
                      ticket,
                      processingTicketStatus: 'DONE',
                    },
                  }),
                ),
              100,
            );
          }
        }
      });
    }
    // if found, dispatch to save jira ticket in store
  };

  const isFormValid = () => {
    return (
      isNotNullOrUndefined(clmComponentConfig) &&
      isStringNotBlank(username) &&
      isStringNotBlank(newTicketTitle) &&
      isStringNotBlank(description) &&
      isStringNotBlank(parentJira?.key) &&
      selectedPlanningIntervals.length > 0
    );
  };

  const isCreateAllTicketsEnabled = () => {
    return isFormValid() && !isCreateAllInProgress && !someInProgress;
  };

  const handleCreateAll = () => {
    if (isCreateAllTicketsEnabled() && isNotNullOrUndefined(clmComponentConfig)) {
      setTimeout(() => setIsCreateAllInProgress(true), 0);

      selectedRows.forEach((index) => {
        const component = tableInfo[index];
        if (isNotNullOrUndefined(component)) {
          const componentJIRATicket = allJiraTickets[component.componentName];

          if (
            isNotNullOrUndefined(componentJIRATicket) &&
            componentJIRATicket.processingTicketStatus !== 'DONE' &&
            !componentJIRATicket.processingInProgress
          ) {
            const newTicket = {
              ...componentJIRATicket,
            };
            newTicket.processingInProgress = true;
            setTimeout(
              () => dispatch(updateJiraTicket({ subcomponentName: component.componentName, jiraPayload: newTicket })),
              0,
            );
          }
        }
      });
    }
  };

  const handleSelectedRows = (rows: Set<number>) => {
    setSelectedRows(rows);
  };

  useEffect(() => {
    if (isNotNullOrUndefined(parentJira)) {
      setNewTicketTitle(parentJira.summary ?? '');
    }
  }, [parentJira]);

  useEffect(() => {
    const inProgress = Object.values(allJiraTickets)
      .map((jira) => jira.processingInProgress)
      .some(Boolean);
    setSomeInProgress(inProgress);
  }, [allJiraTickets]);

  useEffect(() => {
    const inProgress = isCreateAllInProgress && someInProgress;
    setIsCreateAllInProgress(inProgress);
  }, [someInProgress, isCreateAllInProgress]);

  return (
    <Box sx={{ padding: 2 }}>
      <TextField
        label="Username to use as reporter"
        variant="outlined"
        fullWidth
        value={username}
        onChange={handleUsernameChange}
        margin="normal"
        error={isStringBlank(username)}
        helperText={isStringBlank(username) ? 'Username is required' : ''}
        inputProps={{
          'data-testid': `new-jira-ticket-reporter-input`,
        }}
      />
      <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
        <TextField
          label="New Ticket Title"
          variant="outlined"
          fullWidth
          value={newTicketTitle}
          onChange={handleNewTicketTitleChange}
          margin="normal"
          error={isStringBlank(newTicketTitle)}
          helperText={isStringBlank(newTicketTitle) ? 'Ticket title is required' : ''}
          inputProps={{
            'data-testid': `new-jira-ticket-title-input`,
          }}
        />
        <Button
          variant="contained"
          color="primary"
          disabled={isStringBlank(newTicketTitle)}
          onClick={handleMatchTicketsClick}
          sx={{ marginLeft: 2 }}
          data-testid={'match-tickets-button'}
        >
          Match Tickets
        </Button>
      </Box>
      <TextField
        label="Description"
        variant="outlined"
        fullWidth
        multiline
        rows={5}
        value={description}
        onChange={handleDescriptionChange}
        margin="normal"
        placeholder={'Write a description in JIRA directly, switch to text view and copy the content here.'}
        error={isStringBlank(description)}
        helperText={isStringBlank(description) ? 'Ticket description is required' : ''}
        inputProps={{
          'data-testid': `new-jira-ticket-description-input`,
        }}
      />
      <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
        <FormControl component="fieldset" margin="normal">
          <FormLabel component="legend">Ticket Label</FormLabel>
          <RadioGroup
            row
            aria-label="ticket-label"
            name="ticket-label"
            value={ticketLabel}
            onChange={handleTicketLabelChange}
          >
            <FormControlLabel value="none" control={<Radio />} label="None" />
            <FormControlLabel value="tech20" control={<Radio />} label="Tech20" />
            <FormControlLabel value="improvement" control={<Radio />} label="Improvement" />
          </RadioGroup>
        </FormControl>
        <PlanningIntervalDropdown values={selectedPlanningIntervals} onChange={handleDropdownChange} />
      </Box>

      <FormControlLabel
        control={
          <Checkbox
            checked={useSubComponents}
            onChange={handleUseSubComponentsChange}
            disabled={hasFrontendAndBackend}
            inputProps={
              {
                'data-testid': 'include-subcomponents-checkbox',
              } as React.InputHTMLAttributes<HTMLInputElement>
            }
          />
        }
        label="Use SubComponents"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={includeFrontend}
            onChange={handleIncludeFrontendChange}
            disabled={hasFrontendAndBackend}
            inputProps={
              {
                'data-testid': 'include-frontend-checkbox',
              } as React.InputHTMLAttributes<HTMLInputElement>
            }
          />
        }
        label="Include Frontend"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={includeMgmt}
            onChange={handleIncludeMgmtChange}
            disabled={hasFrontendAndBackend}
            inputProps={
              {
                'data-testid': 'include-mgmt-checkbox',
              } as React.InputHTMLAttributes<HTMLInputElement>
            }
          />
        }
        label="Include Mgmt"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={hasFrontendAndBackend}
            onChange={handleHasFrontendAndBackendChange}
            inputProps={
              {
                'data-testid': 'has-frontend-and-backend-checkbox',
              } as React.InputHTMLAttributes<HTMLInputElement>
            }
          />
        }
        label="Has Frontend & Backend"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={includeLibraries}
            onChange={handleIncludeLibrariesChange}
            inputProps={
              {
                'data-testid': 'include-libraries-checkbox',
              } as React.InputHTMLAttributes<HTMLInputElement>
            }
          />
        }
        label="Include Libraries"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={isDryRun}
            onChange={handleIsDryRunChange}
            inputProps={
              {
                'data-testid': 'dry-run-checkbox',
              } as React.InputHTMLAttributes<HTMLInputElement>
            }
          />
        }
        label="Dry Run"
      />

      <br />

      <JiraSubComponentTicketTable
        newJiraTicketTitle={newTicketTitle}
        newJiraTicketDescription={description}
        newJiraTicketReporter={username}
        newJiraTicketLabels={ticketLabel === 'none' ? [] : [ticketLabel]}
        newJiraTicketPlanningIntervals={selectedPlanningIntervals}
        isFormValid={isFormValid()}
        tableInfo={tableInfo}
        useSubComponents={useSubComponents}
        isDryRun={isDryRun}
        handleSelectedRows={handleSelectedRows}
        selectedRows={selectedRows}
      />

      <br />

      <Grid2 container spacing={2}>
        <Button
          variant="contained"
          color="primary"
          data-testid={'create-all-selected-button'}
          disabled={!isCreateAllTicketsEnabled()}
          onClick={handleCreateAll}
          sx={{ marginBottom: 2 }}
        >
          {`Create Tickets for All Selected ${useSubComponents ? 'Subcomponents' : 'Components'}`}
        </Button>

        <Typography variant="body2">Count: {tableInfo.length}</Typography>
      </Grid2>
    </Box>
  );
};
