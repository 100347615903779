import React, { useState } from 'react';

import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  SelectChangeEvent,
} from '@mui/material';

// Define the type for the planning intervals object
export const planningIntervals: Record<string, string> = {
  '93': 'GC PI 24.11',
  '102': 'GC PI 24.12',
  '107': 'GC PI 24.2A',
  '108': 'GC PI 24.2B',
  '109': 'GC PI 24.3A',
  '110': 'GC PI 24.3B',
  '147': 'GC PI 24.4A',
  '153': 'GC PI 24.4B',
};

interface PlanningIntervalDropdownProps {
  values: string[]; // Initial selected values
  onChange: (selectedValues: string[]) => void; // Callback to expose state changes
}

export const PlanningIntervalDropdown: React.FC<PlanningIntervalDropdownProps> = ({ values, onChange }) => {
  const [selectedValues, setSelectedValues] = useState<string[]>(values);

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const newValues = event.target.value as string[];
    setSelectedValues(newValues);
    onChange(newValues); // Expose state changes to the parent component
  };

  // Sort the entries in descending order by the numeric value of the keys
  const sortedEntries = Object.entries(planningIntervals).sort(([keyA], [keyB]) => Number(keyB) - Number(keyA));

  return (
    <Box sx={{ width: 300, margin: '20px auto' }}>
      <FormControl fullWidth>
        <InputLabel id="planning-interval-select-label">Planning Intervals</InputLabel>
        <Select
          labelId="planning-interval-select-label"
          multiple
          value={selectedValues}
          onChange={handleChange}
          renderValue={(selected) => (selected as string[]).map((key) => planningIntervals[key]).join(', ')}
          error={selectedValues.length === 0}
          data-testid="new-jira-ticket-planning-interval-select"
        >
          {sortedEntries.map(([key, label]) => (
            <MenuItem key={key} value={key} data-testid={`new-jira-ticket-planning-interval-menu-${key}`}>
              <Checkbox checked={selectedValues.includes(key)} />
              <ListItemText primary={label} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};
