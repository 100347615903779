import { isNull, isUndefined } from 'lodash';

import {
  CLMComponent,
  CLMComponentsConfig,
  CLMSubcomponent,
  CLMSubcomponentHealth,
  CLMSubcomponentInfo,
  CLMTeam,
  EnvironmentName,
  HealthStatus,
} from '../types';

export const isNullOrUndefined = (value: unknown): value is null | undefined => isNull(value) || isUndefined(value);

export const isNotNullOrUndefined = <T>(value: T | undefined | null): value is T => !isNullOrUndefined(value);

export const isStringNotBlank = (value?: string | undefined | null): value is string =>
  isNotNullOrUndefined(value) && value.trim().length > 0;

export const isStringBlank = (value?: string | undefined | null): value is undefined => !isStringNotBlank(value);

export const uniqByFilter = <T>(array: T[]) => array.filter((value, index) => array.indexOf(value) === index);

export function assertNotNullOrUndefined<T>(
  value: T | undefined | null,
  message = 'Expected value to not be null or undefined',
): asserts value is T {
  if (isNullOrUndefined(value)) {
    throw new Error(message);
  }
}

export const assertGetNotNullOrUndefined = <T>(value: T | null | undefined, message?: string): T => {
  assertNotNullOrUndefined(value, message);
  return value;
};

export const doVersionsMatch = (versions: string[]) => versions.every((version) => version === versions[0]);

export const renderBuildTime = ({
  subcomponent,
  component,
  subcomponentInfo,
}: {
  component: CLMComponent;
  subcomponent: CLMSubcomponent;
  subcomponentInfo: Record<string, Record<string, CLMSubcomponentInfo | undefined>> | undefined;
}) => {
  if (isNullOrUndefined(subcomponentInfo)) return null;

  const componentInfo = subcomponentInfo[component.name][subcomponent.name];

  if (isNullOrUndefined(componentInfo)) return null;

  return new Date(componentInfo.build.time).toLocaleString('en-GB', {
    dateStyle: 'short',
    timeStyle: 'short',
  });
};

export const getSubcomponent = ({
  config,
  subcomponentName,
}: {
  config: CLMComponentsConfig | undefined;
  subcomponentName: string | undefined;
}) => {
  if (isNullOrUndefined(config) || isNullOrUndefined(subcomponentName)) return null;

  for (const component of Object.values(config.components)) {
    for (const subcomponent of Object.values(component.subComponents)) {
      if (subcomponent.name === subcomponentName) {
        return { component, subcomponent };
      }
    }
  }

  return null;
};

export const getTeamName = (teamName: string, clmTeams: CLMTeam[] | undefined) =>
  clmTeams?.find((team) => team.key === teamName)?.name ?? teamName;

export const isRelevantComponent = (comp: CLMComponent, env: EnvironmentName) =>
  isNullOrUndefined(comp.deployedInEnvironments) || comp.deployedInEnvironments.includes(env);

export const getRelevantComponents = (env: EnvironmentName, clmComponentsConfig: Record<string, CLMComponent>) =>
  Object.values(clmComponentsConfig)
    .filter((comp) => isRelevantComponent(comp, env))
    .sort((a, b) => a.name.localeCompare(b.name));

export const getIrrelevantComponents = (env: EnvironmentName, clmComponentsConfig: Record<string, CLMComponent>) =>
  Object.values(clmComponentsConfig).filter((comp) => !isRelevantComponent(comp, env));

export const getComponentMapByHealthStatus = (
  components: CLMComponent[] | undefined,
  subcomponentHealth: Record<string, Record<string, CLMSubcomponentHealth>>,
) => {
  const componentsByHealthStatus: Record<string, HealthStatus> = {};

  if (isNotNullOrUndefined(components)) {
    components.forEach((component) => {
      Object.values(component.subComponents).forEach((subcomponent) => {
        const clmSubcomponentHealth = subcomponentHealth[component.name];
        if (isNotNullOrUndefined(clmSubcomponentHealth)) {
          const healthResponse = clmSubcomponentHealth[subcomponent.name];
          let healthStatus = healthResponse.status;
          const livenessProbeStatus = healthResponse.components?.livenessState;
          const readinessProbeStatus = healthResponse.components?.readinessState;
          if (healthStatus === 'UP') {
            if (isNotNullOrUndefined(livenessProbeStatus) && livenessProbeStatus.status !== 'UP') {
              healthStatus = livenessProbeStatus.status;
            } else if (isNotNullOrUndefined(readinessProbeStatus) && readinessProbeStatus.status !== 'UP') {
              healthStatus = readinessProbeStatus.status;
            }
          }
          componentsByHealthStatus[subcomponent.name] = healthStatus;
        } else {
          componentsByHealthStatus[subcomponent.name] = 'UNKNOWN';
        }
      });
    });
  }
  return componentsByHealthStatus;
};
