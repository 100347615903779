import React from 'react';

import { JBColors } from '@juliusbaer-gcrm/gcrm-frontend-components';
import { Box, Grid2, Typography } from '@mui/material';

import { useCLMComponentsConfig } from '../hooks/useCLMComponentsConfig';
import { useCLMSubcomponentHealth } from '../hooks/useCLMSubcomponentHealth';
import { useCLMSubcomponentInfo } from '../hooks/useCLMSubcomponentInfo';
import { CLMComponent } from '../types';
import { getTeamName } from '../utils/utils';
import { CLMSubcomponentListItem } from './CLMSubcomponentListItem';

type Props = {
  component: CLMComponent;
};

export const CLMComponentListItem: React.FC<Props> = ({ component }) => {
  const { data } = useCLMComponentsConfig();
  const { data: subcomponentHealth } = useCLMSubcomponentHealth(data?.environmentName, data?.components);
  const { data: subcomponentInfo } = useCLMSubcomponentInfo(data?.environmentName, data?.components);
  const teamName = getTeamName(component.team, data?.clmTeams);

  return (
    <Grid2 size={1}>
      <Box
        sx={{
          backgroundColor: JBColors.core.stone['40'],
          padding: '1rem',
          margin: '10px',
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
        }}
        data-testid={`clm-component-list-item-${component.name}`}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              gap: '5px',
              flexDirection: 'column',
            }}
          >
            <Box
              sx={{
                fontWeight: 'bold',
                fontSize: '1.1rem',
              }}
            >
              {component.name}
            </Box>
            <Box
              sx={{
                fontSize: '0.8rem',
              }}
            >
              {teamName}
            </Box>
          </Box>
          <Box>
            <img
              src={`/teams/${component.team}.png`}
              alt={`${component.team} Image`}
              style={{
                width: '50px',
              }}
            />
          </Box>
        </Box>
        <Box>
          <Typography
            sx={{
              fontWeight: 'bold',
              fontSize: '0.8rem',
            }}
          >
            Subcomponents
          </Typography>
          <Box>
            {Object.values(component.subComponents).map((subcomponent, index) => (
              <CLMSubcomponentListItem
                key={subcomponent.name}
                index={index}
                component={component}
                subcomponent={subcomponent}
                subcomponentHealth={subcomponentHealth}
                subcomponentInfo={subcomponentInfo}
              />
            ))}
          </Box>
        </Box>
      </Box>
    </Grid2>
  );
};
