import { useQuery } from '@tanstack/react-query';
/* eslint-disable import/no-named-as-default-member */
import axios, { AxiosError } from 'axios';

import { axiosInstance } from '../shared/axios/axiosConfig';
import { CLMComponent, CLMSubcomponentHealth, EnvironmentName, HealthStatus } from '../types';
import {
  assertNotNullOrUndefined,
  getIrrelevantComponents,
  getRelevantComponents,
  isNotNullOrUndefined,
} from '../utils/utils';

export const useCLMSubcomponentHealth = (env?: EnvironmentName, components?: Record<string, CLMComponent>) => {
  const query = useQuery<Record<string, Record<string, CLMSubcomponentHealth>> | undefined, AxiosError>({
    queryKey: ['clm-subcomponent-health'],
    queryFn: async () => {
      assertNotNullOrUndefined(env);
      assertNotNullOrUndefined(components);
      const componentsAsArray = getRelevantComponents(env, components);

      const subcomponents = componentsAsArray.flatMap((component) =>
        Object.values(component.subComponents).map((subcomponent) =>
          fetchData(subcomponent.name, component.actuatorBaseUrl),
        ),
      );
      const results = await Promise.all(subcomponents);

      const temp: Record<string, CLMSubcomponentHealth> = {};
      results.forEach((result) => (temp[result.name] = result.payload));

      getIrrelevantComponents(env, components).forEach((comp) => {
        Object.values(comp.subComponents).forEach((subcomp) => {
          temp[subcomp.name] = {
            groups: [],
            status: 'NOT_DEPLOYED',
          };
        });
      });

      const subcomponentHealth: Record<string, Record<string, CLMSubcomponentHealth>> = {};
      Object.values(components).forEach((component) => {
        subcomponentHealth[component.name] = {};

        Object.values(component.subComponents).forEach((subcomponent) => {
          subcomponentHealth[component.name][subcomponent.name] = temp[subcomponent.name];
        });
      });

      return subcomponentHealth;
    },
    retry: 1,
    enabled: isNotNullOrUndefined(components) && isNotNullOrUndefined(env),
  });

  const fetchData = async (subcomponentName: string, baseUrl?: string) => {
    try {
      const { data } = await axiosInstance.get(`/backend/${subcomponentName}${baseUrl}/health`);

      return {
        name: subcomponentName,
        payload: data,
      };
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status !== 404) {
        const data = error.response?.data;
        const contentType = error.response?.headers['content-type'];

        const validHealthStatuses: HealthStatus[] = ['UP', 'DOWN', 'OUT_OF_SERVICE', 'UNKNOWN', 'NOT_DEPLOYED'];

        if (contentType === 'application/json' && validHealthStatuses.includes(data.status)) {
          return {
            name: subcomponentName,
            payload: data,
          };
        } else {
          return {
            name: subcomponentName,
            payload: {
              status: 'UNKNOWN',
              groups: [],
            },
          };
        }
      } else {
        return {
          name: subcomponentName,
          payload: {
            status: 'UNKNOWN',
            groups: [],
          },
        };
      }
    }
  };

  return query;
};
