import React from 'react';

import { JBColors } from '@juliusbaer-gcrm/gcrm-frontend-components';
import { Box } from '@mui/material';

import { CLMComponent, CLMComponentValidation } from '../types';
import { doVersionsMatch, isNotNullOrUndefined, isNullOrUndefined } from '../utils/utils';
import { useCLMComponentsConfig } from './useCLMComponentsConfig';
import { useCLMSubcomponentHealth } from './useCLMSubcomponentHealth';
import { useCLMSubcomponentInfo } from './useCLMSubcomponentInfo';

type Props = {
  components?: Record<string, CLMComponent>;
};

export const useValidation = ({ components }: Props) => {
  const { data } = useCLMComponentsConfig();
  const { data: subcomponentHealth } = useCLMSubcomponentHealth(data?.environmentName, data?.components);
  const { data: subcomponentInfo } = useCLMSubcomponentInfo(data?.environmentName, data?.components);

  const validation: Record<string, Record<string, CLMComponentValidation>> = {};

  if (isNotNullOrUndefined(components) && isNotNullOrUndefined(data)) {
    const componentsAsArray = Object.values(components);

    componentsAsArray.forEach((component) => {
      validation[component.name] = {};

      const subcomponentNames = Object.values(component.subComponents).map((subcomponent) => subcomponent.name);

      Object.values(component.subComponents).forEach((subcomponent) => {
        const deployment: string[] = [];
        const warnings: string[] = [];
        const errors: string[] = [];

        if (
          isNotNullOrUndefined(component.deployedInEnvironments) &&
          !component.deployedInEnvironments.includes(data.environmentName)
        ) {
          deployment.push('Component is not deployed on this environment.');
        } else {
          if (isNotNullOrUndefined(subcomponentInfo)) {
            const info = subcomponentInfo[component.name][subcomponent.name];

            if (isNullOrUndefined(info)) {
              warnings.push('Could not fetch subcomponent info');
            } else {
              if (info.build.version === '0.0.0') {
                warnings.push('Build version is incorrectly configured.');
              }

              const semanticVersionRegex = /^\d+\.\d+\.\d+$/;
              if (!semanticVersionRegex.test(info.build.version)) {
                warnings.push('A feature branch is currently deployed instead of the main branch.');
              }

              const versions = subcomponentNames.map((sc) => {
                const scInfo = subcomponentInfo[component.name][sc];
                if (isNotNullOrUndefined(scInfo)) {
                  return scInfo.build.version;
                } else {
                  return '0.0.0';
                }
              });

              if (!doVersionsMatch(versions) && component.shouldSubcomponentsHaveMatchingVersion) {
                warnings.push('Subcomponents have mismatching versions');
              }

              if (new Date(info.build.time) < new Date(new Date().setMonth(new Date().getMonth() - 3))) {
                warnings.push('Build is older than 3 months');
              }
            }
          } else {
            warnings.push('Could not fetch subcomponent info');
          }

          if (isNotNullOrUndefined(subcomponentHealth)) {
            const health = subcomponentHealth[component.name][subcomponent.name];

            if (health.status !== 'UP') {
              errors.push(`Subcomponent health status is ${health.status}`);
            }

            const healthComponents = health.components;

            if (isNotNullOrUndefined(healthComponents)) {
              const livenessState = healthComponents.livenessState;
              const readinessState = healthComponents.readinessState;
              if (
                health.status !== 'UP' &&
                ((isNotNullOrUndefined(livenessState) && livenessState.status === 'UP') ||
                  (isNotNullOrUndefined(readinessState) && readinessState.status === 'UP'))
              ) {
                errors.push(`Subcomponent is ${health.status} but liveness or readiness probes are up`);
              }
              if (isNotNullOrUndefined(livenessState) && livenessState.status !== 'UP') {
                errors.push(`Subcomponent liveness probe is ${livenessState.status}`);
              }
              if (isNotNullOrUndefined(readinessState) && readinessState.status !== 'UP') {
                errors.push(`Subcomponent readiness probe is ${readinessState.status}`);
              }
            }
          } else {
            errors.push('Could not fetch subcomponent health');
          }
        }

        validation[component.name][subcomponent.name] = {
          info: deployment,
          warnings,
          errors,
          tooltip: () => {
            const tooltip: React.ReactNode | null = null;
            const tooltipWarnings: React.ReactNode[] = [];
            const tooltipErrors: React.ReactNode[] = [];

            if (warnings.length > 0) {
              warnings.forEach((warning) => {
                tooltipWarnings.push(<Box key={warning.toLowerCase().replaceAll(' ', '-')}>- {warning}</Box>);
              });
            }

            if (errors.length > 0) {
              errors.forEach((error) => {
                tooltipErrors.push(<Box key={error.toLowerCase().replaceAll(' ', '-')}>- {error}</Box>);
              });
            }

            if (tooltipWarnings.length > 0 || tooltipErrors.length > 0) {
              return (
                <Box>
                  {tooltipWarnings.length > 0 ? <Box sx={{ fontWeight: 'bold' }}>Warnings</Box> : null}
                  {tooltipWarnings}
                  {tooltipErrors.length > 0 ? <Box sx={{ fontWeight: 'bold' }}>Errors</Box> : null}
                  {tooltipErrors}
                </Box>
              );
            }

            return tooltip;
          },
          color: () => {
            if (errors.length === 0 && warnings.length === 0 && deployment.length === 0) {
              return JBColors.support.green['100'];
            } else if (errors.length > 0) {
              return JBColors.support.red['100'];
            } else if (warnings.length > 0) {
              return JBColors.support.yellow['110'];
            } else if (deployment.length > 0) {
              return JBColors.support.blue['70'];
            } else {
              return JBColors.support.red['100'];
            }
          },
        };
      });
    });
  }

  return validation;
};
