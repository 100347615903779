import api from './Api';
import {
  CreateJiraIssueResponse,
  JiraInOutWardIssue,
  JiraIssueData,
  Link2JiraTicketsRequest,
} from '../types/jira.types';
import { JiraLinkedIssue, JiraTicket } from '../store/jiraStore/jiraReducer';
import { assertNotNullOrUndefined, isNotNullOrUndefined } from '../utils/utils';
import { axiosInstance } from '../shared/axios/axiosConfig';

export interface JiraProjectFixVersion {
  id: string;
  name: string;
  archived: boolean;
  released: boolean;
  projectId: number;
}

export interface JiraIssueLinkType {
  id: string;
  name: string;
  inward: string;
  outward: string;
  self: string;
}

export interface JiraIssueLinkTypes {
  issueLinkTypes: JiraIssueLinkType[];
}

export interface JiraProject {
  id: string;
  key: string;
  name: string;
  avatarUrls: { [size: string]: string };
}

const mapLinkedIssue = (linkedIssue: JiraInOutWardIssue): JiraTicket => {
  assertNotNullOrUndefined(linkedIssue);
  return {
    summary: linkedIssue.fields.summary,
    key: linkedIssue.key,
    issueType: linkedIssue.fields.issuetype.name,
  };
};

const JiraService = {
  async getIssue(key: string) {
    const response = await api.get(`/jira/issue/${key}`);
    const issueData: JiraIssueData = response.data;
    const mappedIssueLinks: JiraLinkedIssue[] = (issueData.fields.issuelinks ?? [])
      .filter((link) => isNotNullOrUndefined(link.outwardIssue) || isNotNullOrUndefined(link.inwardIssue))
      .map((link) => {
        return {
          linkTypeName: link.type.name,
          inwardIssue: isNotNullOrUndefined(link.inwardIssue) ? mapLinkedIssue(link.inwardIssue) : undefined,
          outwardIssue: isNotNullOrUndefined(link.outwardIssue) ? mapLinkedIssue(link.outwardIssue) : undefined,
        };
      });
    return {
      summary: issueData.fields.summary,
      key: issueData.key,
      issueType: issueData.fields.issuetype.name,
      status: issueData.fields.status?.name,
      description: issueData.fields.description,
      assignee: issueData.fields.assignee,
      issuelinks: mappedIssueLinks,
      id: issueData.id,
      customfield_11519: issueData.fields.customfield_11519,
    } as JiraTicket;
  },
  async createIssue(jiraTicket: JiraTicket) {
    const jiraIssueData: JiraIssueData = {
      fields: {
        summary: jiraTicket.summary,
        description: jiraTicket.description ?? '',
        issuetype: { name: jiraTicket.issueType },
        project: {
          key: jiraTicket.projectKey ?? '',
        },
        labels: jiraTicket.labels ?? [],
        reporter: {
          name: jiraTicket.reporter ?? '',
        },
        customfield_11519: jiraTicket.customfield_11519 ?? [],
      },
    };
    const response = await api.post(`/jira/issue`, jiraIssueData);
    const issueData: CreateJiraIssueResponse = response.data;
    return issueData.key;
  },
  async linkIssues(linkIssuesData: Link2JiraTicketsRequest) {
    await api.post(`/jira/link-issues`, linkIssuesData);
  },
  async getProjects(keys: string) {
    return axiosInstance.get<JiraProject[]>(`/jira/projects`, {
      params: { keys },
    });
  },
  async getIssueLinkTypes() {
    return axiosInstance.get<JiraIssueLinkTypes>('/jira/issue-link-types');
  },
  async getProjectFixVersions(projectKey: string) {
    return axiosInstance.get<JiraProjectFixVersion[]>(`/jira/projects/${projectKey}/versions`);
  },
};

export default JiraService;
