import React from 'react';

import { QueryClient } from '@tanstack/react-query';
import { createRootRouteWithContext, createRoute, createRouter, redirect } from '@tanstack/react-router';

import App from '../App';
import { Loader } from '../components/Loader';
import { getRuntimeConfig } from '../hooks/useRuntimeConfig';
import { infoOverviewFilter } from '../schemas/infoOverviewFilter';
import { RuntimeConfig } from '../types';
import DrilldownPage from './DrilldownPage';
import ImpersonatePage from './ImpersonatePage';
import InfoOverviewPage from './InfoOverviewPage';
import JiraCrudPage from './JiraCrudPage';
import JiraTicketCreator from './JiraTicketCreator';

const rootRoute = createRootRouteWithContext<{
  queryClient: QueryClient;
}>()({
  component: App,
});

const indexRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: '/',
  loader: async () => redirect({ to: '/impersonate' }),
  pendingComponent: () => <Loader />,
});

const impersonateRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: '/impersonate',
  component: ImpersonatePage,
  pendingComponent: () => <Loader />,
  beforeLoad: async ({ context }) => {
    const queryClient = context.queryClient;

    const runtimeConfig = await queryClient.ensureQueryData<RuntimeConfig>(getRuntimeConfig());

    if (!runtimeConfig.featureFlags?.userImpersonation.enabled) {
      throw redirect({
        to: '/info',
      });
    }
  },
});

const infoOverviewRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: '/info',
  component: InfoOverviewPage,
  validateSearch: (search) => infoOverviewFilter.parse(search),
});

const infoDrilldownRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: '/info/component/$subcomponent',
  component: DrilldownPage,
});

const jiraCrudRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: '/jira-crud',
  component: JiraCrudPage,
  pendingComponent: () => <Loader />,
  beforeLoad: async ({ context }) => {
    const queryClient = context.queryClient;

    const runtimeConfig = await queryClient.ensureQueryData<RuntimeConfig>(getRuntimeConfig());

    if (!runtimeConfig.featureFlags?.jiraCrud?.enabled) {
      throw redirect({
        to: '/',
      });
    }
  },
});

const jiraCreatorRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: '/jira-creator',
  component: JiraTicketCreator,
  pendingComponent: () => <Loader />,
  beforeLoad: async ({ context }) => {
    const queryClient = context.queryClient;

    const runtimeConfig = await queryClient.ensureQueryData<RuntimeConfig>(getRuntimeConfig());

    if (!runtimeConfig.featureFlags?.jiraCreator?.enabled) {
      throw redirect({
        to: '/',
      });
    }
  },
});

const routeTree = rootRoute.addChildren([
  indexRoute,
  impersonateRoute,
  infoOverviewRoute,
  infoDrilldownRoute,
  jiraCrudRoute,
  jiraCreatorRoute,
]);

export const router = createRouter({
  routeTree,
  context: {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    queryClient: undefined!,
  },
});

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}
