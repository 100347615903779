import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

/* eslint-disable import/no-named-as-default-member */
import { axiosInstance } from '../shared/axios/axiosConfig';
import { CLMComponent, CLMSubcomponentInfo, EnvironmentName } from '../types';
import {
  assertNotNullOrUndefined,
  getIrrelevantComponents,
  getRelevantComponents,
  isNotNullOrUndefined,
} from '../utils/utils';

export const useCLMSubcomponentInfo = (env?: EnvironmentName, components?: Record<string, CLMComponent>) => {
  const query = useQuery({
    queryKey: ['clm-subcomponent-info'],
    queryFn: async () => {
      assertNotNullOrUndefined(components);
      assertNotNullOrUndefined(env);
      const componentsAsArray = getRelevantComponents(env, components);

      const subcomponents = componentsAsArray.flatMap((component) =>
        Object.values(component.subComponents).map((subcomponent) =>
          fetchData(subcomponent.name, component.actuatorBaseUrl),
        ),
      );
      const results = await Promise.all(subcomponents);

      const temp: Record<string, CLMSubcomponentInfo | undefined> = {};
      results.forEach((result) => (temp[result.name] = result.payload));

      getIrrelevantComponents(env, components).forEach((comp) =>
        Object.values(comp.subComponents).forEach(
          (subcomp) =>
            (temp[subcomp.name] = {
              build: {
                artifact: 'unknown',
                group: 'unknown',
                name: subcomp.name,
                time: new Date(),
                version: 'not deployed',
              },
            }),
        ),
      );

      const subcomponentInfo: Record<string, Record<string, CLMSubcomponentInfo | undefined>> = {};
      Object.values(components).forEach((component) => {
        subcomponentInfo[component.name] = {};

        Object.values(component.subComponents).forEach((subcomponent) => {
          subcomponentInfo[component.name][subcomponent.name] = temp[subcomponent.name];
        });
      });

      return subcomponentInfo;
    },
    retry: 1,
    enabled: isNotNullOrUndefined(components) && isNotNullOrUndefined(env),
  });

  const fetchData = async (subComponentName: string, actuatorBaseUrl?: string) => {
    try {
      const { data } = await axiosInstance.get(`/backend/${subComponentName}${actuatorBaseUrl}/info`);

      return {
        name: subComponentName,
        payload: data,
      };
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return {
          name: subComponentName,
          payload: undefined,
        };
      } else {
        throw error;
      }
    }
  };

  return query;
};
