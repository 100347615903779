import React, { useState } from 'react';

import { Box, Button, Typography, CircularProgress, Card, CardContent } from '@mui/material';

import JiraService, { JiraIssueLinkType } from '../../services/JiraService';

export const JiraIssueLinksView: React.FC = () => {
  const [issueLinkTypes, setIssueLinkTypes] = useState<JiraIssueLinkType[] | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleLoadIssueLinkTypes = async () => {
    setLoading(true);
    setError(null);
    setIssueLinkTypes(null);

    try {
      const response = await JiraService.getIssueLinkTypes();
      setIssueLinkTypes(response.data.issueLinkTypes);
    } catch {
      setError('Failed to load issue link types. Please check the API.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ width: '100%', padding: 2 }}>
      <Typography variant="h5" gutterBottom>
        JIRA Issue Link Types
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={handleLoadIssueLinkTypes}
        disabled={loading}
        sx={{ marginTop: 2 }}
      >
        {loading ? <CircularProgress size={24} color="inherit" /> : 'Load Issue Link Types'}
      </Button>

      {error && (
        <Typography variant="body2" color="error" sx={{ marginTop: 2 }}>
          {error}
        </Typography>
      )}

      {issueLinkTypes && (
        <Box sx={{ marginTop: 4 }}>
          <Typography variant="h6" gutterBottom>
            Loaded Issue Link Types
          </Typography>
          <Typography variant="caption">Note: in bold is the text to be used in the link API</Typography>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: 'repeat(2, 1fr)',
              gap: 2,
            }}
          >
            {issueLinkTypes.map((linkType) => (
              <Card key={linkType.id}>
                <CardContent>
                  <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                    {linkType.name}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    Inward: {linkType.inward}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    Outward: {linkType.outward}
                  </Typography>
                </CardContent>
              </Card>
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
};
