import React from 'react';

import { Box } from '@mui/material';

import { useCLMComponentsConfig } from '../hooks/useCLMComponentsConfig';
import { useCLMSubcomponentInfo } from '../hooks/useCLMSubcomponentInfo';
import { useValidation } from '../hooks/useValidation';
import { CLMComponent, CLMSubcomponent } from '../types';
import { getTeamName, isNotNullOrUndefined } from '../utils/utils';

type Props = {
  component: CLMComponent;
  subcomponent: CLMSubcomponent;
};

export const ComponentConfig: React.FC<Props> = ({ component, subcomponent }) => {
  const { data } = useCLMComponentsConfig();
  const { data: subcomponentInfo } = useCLMSubcomponentInfo(data?.environmentName, data?.components);
  const validation = useValidation({ components: data?.components });
  const teamName = getTeamName(component.team, data?.clmTeams);

  return (
    <Box>
      {component.name != undefined || null ? (
        <div>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'start',
                gap: '1rem',
              }}
            >
              <Box>
                <img
                  src={`/teams/${component.team}.png`}
                  alt={`${component.team} Image`}
                  style={{
                    width: '50px',
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '5px',
                  marginBottom: '10px',
                }}
              >
                <Box
                  sx={{
                    fontSize: '1.1rem',
                    fontWeight: 'bold',
                  }}
                  data-testid="component-config-name"
                >
                  {component.name}
                </Box>
                <Box
                  sx={{
                    fontSize: '0.8rem',
                  }}
                >
                  {teamName}
                </Box>
              </Box>
            </Box>

            <div>
              <strong>Subcomponents</strong>
              <ul
                style={{
                  padding: 0,
                  marginTop: 5,
                  marginLeft: 20,
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2.5,
                }}
                data-testid="component-config-subcomponents"
              >
                {Object.values(component.subComponents).map((subcomponent) => (
                  <li
                    key={subcomponent.name}
                    style={{
                      maxWidth: '25%',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Box>{subcomponent.name.split('-')[subcomponent.name.split('-').length - 1]}</Box>
                      {isNotNullOrUndefined(subcomponentInfo)
                        ? subcomponentInfo[component.name][subcomponent.name]?.build.version
                        : null}
                    </Box>
                  </li>
                ))}
              </ul>
            </div>

            {isNotNullOrUndefined(validation) ? (
              <>
                {validation[component.name][subcomponent.name].errors.length > 0 ? (
                  <Box data-testid="component-config-errors">
                    <strong>Errors</strong>
                    <ul
                      style={{
                        padding: 0,
                        marginTop: 5,
                        marginLeft: 20,
                      }}
                    >
                      {validation[component.name][subcomponent.name].errors.map((error) => (
                        <li key={error}>{error}</li>
                      ))}
                    </ul>
                  </Box>
                ) : null}
                {validation[component.name][subcomponent.name].warnings.length > 0 ? (
                  <Box data-testid="component-config-warnings">
                    <strong>Warnings</strong>
                    <ul
                      style={{
                        padding: 0,
                        marginTop: 5,
                        marginLeft: 20,
                      }}
                    >
                      {validation[component.name][subcomponent.name].warnings.map((warning) => (
                        <li key={warning}>{warning}</li>
                      ))}
                    </ul>
                  </Box>
                ) : null}
              </>
            ) : null}
          </Box>
        </div>
      ) : (
        <div> Error: Component does not exist </div>
      )}
    </Box>
  );
};
