import React, { useState, useEffect } from 'react';

import {
  Box,
  Button,
  CircularProgress,
  Typography,
  Card,
  CardContent,
  Chip,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Divider,
  Checkbox,
  ListItemText,
} from '@mui/material';

import JiraService, { JiraProjectFixVersion } from '../../services/JiraService';

export const JiraFixVersionsView: React.FC = () => {
  const [project, setProject] = useState<string>('GC');
  const [fixVersions, setFixVersions] = useState<JiraProjectFixVersion[] | null>(null);
  const [filteredFixVersions, setFilteredFixVersions] = useState<JiraProjectFixVersion[] | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  // Multiselect options and state
  const [activeFilter, setActiveFilter] = useState<string[]>(['Active', 'Archived']);
  const [releaseFilter, setReleaseFilter] = useState<string[]>(['Released', 'Unreleased']);

  const handleLoadFixVersions = async () => {
    setLoading(true);
    setError(null);
    setFixVersions(null);
    setFilteredFixVersions(null);

    try {
      const response = await JiraService.getProjectFixVersions(project);
      setFixVersions(response.data);
    } catch {
      setError('Failed to load fix versions. Please check the API and project key.');
    } finally {
      setLoading(false);
    }
  };

  // Update filtered results whenever fixVersions or filters change
  useEffect(() => {
    // Function to filter fix versions based on the selected dropdown options
    const applyFilters = (versions: JiraProjectFixVersion[] | null) => {
      if (!versions) return null;

      return versions.filter((version) => {
        const activeCondition =
          (activeFilter.includes('Active') && !version.archived) ||
          (activeFilter.includes('Archived') && version.archived);
        const releaseCondition =
          (releaseFilter.includes('Released') && version.released) ||
          (releaseFilter.includes('Unreleased') && !version.released);
        return activeCondition && releaseCondition;
      });
    };

    setFilteredFixVersions(applyFilters(fixVersions));
  }, [fixVersions, activeFilter, releaseFilter]);

  return (
    <Box sx={{ padding: 2, maxWidth: '100%', margin: '0 auto' }}>
      <Typography variant="h5" gutterBottom>
        JIRA Fix Versions
      </Typography>

      <TextField
        label="Project Key"
        variant="outlined"
        fullWidth
        value={project}
        onChange={(e) => setProject(e.target.value)}
        margin="normal"
      />

      <Button
        variant="contained"
        color="primary"
        onClick={handleLoadFixVersions}
        disabled={loading}
        sx={{ marginTop: 2, marginBottom: 2 }}
      >
        {loading ? <CircularProgress size={24} color="inherit" /> : 'Load Fix Versions'}
      </Button>

      <Divider sx={{ marginY: 2 }} />

      <Typography variant="h6" gutterBottom>
        Loaded Versions
      </Typography>

      <Box sx={{ display: 'flex', gap: 2, marginBottom: 2 }}>
        <FormControl fullWidth>
          <InputLabel id="active-filter-label">Active Status</InputLabel>
          <Select
            labelId="active-filter-label"
            multiple
            value={activeFilter}
            onChange={(e) => setActiveFilter(e.target.value as string[])}
            renderValue={(selected) => selected.join(', ')}
          >
            {['Active', 'Archived'].map((option) => (
              <MenuItem key={option} value={option}>
                <Checkbox checked={activeFilter.includes(option)} />
                <ListItemText primary={option} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel id="release-filter-label">Release Status</InputLabel>
          <Select
            labelId="release-filter-label"
            multiple
            value={releaseFilter}
            onChange={(e) => setReleaseFilter(e.target.value as string[])}
            renderValue={(selected) => selected.join(', ')}
          >
            {['Released', 'Unreleased'].map((option) => (
              <MenuItem key={option} value={option}>
                <Checkbox checked={releaseFilter.includes(option)} />
                <ListItemText primary={option} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      {error && (
        <Typography variant="body2" color="error" sx={{ marginTop: 2 }}>
          {error}
        </Typography>
      )}

      {filteredFixVersions && (
        <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: 2, marginTop: 2 }}>
          {filteredFixVersions.map((version) => (
            <Card key={version.id} sx={{ display: 'flex', flexDirection: 'column' }}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  {version.name}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  ID: {version.id}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Project ID: {version.projectId}
                </Typography>
                <Box sx={{ marginTop: 1 }}>
                  <Chip
                    label={version.released ? 'Released' : 'Unreleased'}
                    color={version.released ? 'success' : 'warning'}
                    size="small"
                    sx={{ marginRight: 1 }}
                  />
                  <Chip
                    label={version.archived ? 'Archived' : 'Active'}
                    color={version.archived ? 'default' : 'primary'}
                    size="small"
                  />
                </Box>
              </CardContent>
            </Card>
          ))}
        </Box>
      )}
    </Box>
  );
};
