import React, { useEffect, useState } from 'react';

import { Checkbox, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import { CLMTeam } from '../../types';
import { NewJiraTicket } from './NewJiraTicket';

export interface TableInformation {
  componentName: string;
  team: CLMTeam;
}

interface Props {
  newJiraTicketTitle: string;
  newJiraTicketDescription: string;
  newJiraTicketLabels: string[];
  newJiraTicketPlanningIntervals: string[];
  newJiraTicketReporter: string;
  isFormValid: boolean;
  tableInfo: TableInformation[];
  useSubComponents: boolean;
  isDryRun: boolean;
  handleSelectedRows: (rows: Set<number>) => void;
  selectedRows: Set<number>;
}

export const JiraSubComponentTicketTable: React.FC<Props> = ({
  newJiraTicketTitle,
  newJiraTicketDescription,
  newJiraTicketLabels,
  newJiraTicketPlanningIntervals,
  newJiraTicketReporter,
  isFormValid,
  tableInfo,
  useSubComponents,
  isDryRun,
  handleSelectedRows,
  selectedRows,
}) => {
  const [selectAllRows, setSelectAllRows] = useState(false);

  const handleRowCheckboxChange = (index: number) => {
    const updatedSelectedRows = new Set(selectedRows);
    if (updatedSelectedRows.has(index)) {
      updatedSelectedRows.delete(index);
    } else {
      updatedSelectedRows.add(index);
    }
    handleSelectedRows(updatedSelectedRows);
  };

  const handleSelectAllCheckboxChange = () => {
    setSelectAllRows(!selectAllRows);
    const updatedSelectedRows = new Set<number>();
    if (!selectAllRows) {
      // Select all rows
      let index = 0;
      tableInfo.forEach(() => {
        updatedSelectedRows.add(index);
        index++;
      });
    }
    handleSelectedRows(updatedSelectedRows);
  };

  const isCreateTicketEnabled = (rowIndex: number) => {
    return selectedRows.has(rowIndex) && isFormValid;
  };

  useEffect(() => {
    setSelectAllRows(false);
    handleSelectedRows(new Set<number>());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableInfo.length]);

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow sx={{ backgroundColor: 'lightgrey' }}>
            <TableCell>
              <Checkbox
                checked={selectAllRows}
                onChange={handleSelectAllCheckboxChange}
                inputProps={
                  {
                    'data-testid': 'select-all-components-checkbox',
                  } as React.InputHTMLAttributes<HTMLInputElement>
                }
              />
            </TableCell>
            <TableCell>{useSubComponents ? 'Subcomponent Name' : 'Component Name'}</TableCell>
            <TableCell>Team</TableCell>
            <TableCell>Jira Project Key</TableCell>
            <TableCell>New Ticket Title</TableCell>
            <TableCell>New JIRA Ticket</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tableInfo.map((rowInfo, index) => (
            <TableRow key={index}>
              <TableCell>
                <Checkbox
                  checked={selectedRows.has(index)}
                  onChange={() => handleRowCheckboxChange(index)}
                  inputProps={
                    {
                      'data-testid': `select-component-checkbox-${rowInfo.componentName}`,
                    } as React.InputHTMLAttributes<HTMLInputElement>
                  }
                />
              </TableCell>
              <TableCell>{rowInfo.componentName}</TableCell>
              <TableCell>{rowInfo.team.shortName}</TableCell>
              <TableCell>{rowInfo.team.jiraProject}</TableCell>
              <TableCell>{`${rowInfo.componentName}: ${newJiraTicketTitle ?? '<New Ticket Title>'}`}</TableCell>
              <TableCell>
                <NewJiraTicket
                  rowInfo={rowInfo}
                  shouldCreateTicket={isCreateTicketEnabled(index)}
                  isDryRun={isDryRun}
                  jiraTicket={{
                    summary: `${rowInfo.componentName}: ${newJiraTicketTitle}`,
                    projectKey: rowInfo.team.jiraProject,
                    issueType: 'Story',
                    description: newJiraTicketDescription,
                    labels: newJiraTicketLabels,
                    reporter: newJiraTicketReporter,
                    customfield_11519: newJiraTicketPlanningIntervals,
                  }}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
