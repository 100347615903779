import './App.css';

import React, { useState } from 'react';

import { Menu } from '@mui/icons-material';
import { Box, Button, Drawer } from '@mui/material';
import { Link, Outlet } from '@tanstack/react-router';

import { useRuntimeConfig } from './hooks/useRuntimeConfig';

function App() {
  const [open, setOpen] = useState(false);
  const runtimeConfig = useRuntimeConfig();

  const toggleDrawer = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  return (
    <Box>
      <Box
        sx={{
          position: 'absolute',
          top: '1rem',
          left: '1rem',
        }}
      >
        <Button onClick={() => toggleDrawer(true)} data-testid="menu-burger-button">
          <Menu />
        </Button>
      </Box>
      <Drawer open={open} onClose={() => toggleDrawer(false)}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            padding: '1rem',
            gap: '.5rem',
          }}
        >
          {(runtimeConfig?.data?.featureFlags?.userImpersonation.enabled ?? false) && (
            <Link to="/" onClick={() => toggleDrawer(false)} data-testid="menu-opt-clm-impersonation">
              CLM Login
            </Link>
          )}
          <Link to="/info" onClick={() => toggleDrawer(false)} data-testid="menu-opt-clm-component-status">
            CLM Components Status
          </Link>
          {(runtimeConfig?.data?.featureFlags?.jiraCrud.enabled ?? false) && (
            <Link to="/jira-crud" onClick={() => toggleDrawer(false)} data-testid="menu-opt-jira-crud">
              JIRA CRUD
            </Link>
          )}
          {(runtimeConfig?.data?.featureFlags?.jiraCreator?.enabled ?? false) && (
            <Link to="/jira-creator" onClick={() => toggleDrawer(false)} data-testid="menu-opt-jira-creator">
              JIRA Tickets
            </Link>
          )}
        </Box>
      </Drawer>
      <Outlet />
    </Box>
  );
}

export default App;
