import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Alert, Box, Button, CircularProgress, TextField, Typography } from '@mui/material';
import { JBCopyButton } from '@juliusbaer-gcrm/gcrm-frontend-components';

import { isNotNullOrUndefined, isStringNotBlank } from '../../utils/utils';
import { JiraTicketWithLink } from './JiraTicketWithLink';
import { JiraTicket, updateParentJiraTicket } from '../../store/jiraStore/jiraReducer';
import JiraService from '../../services/JiraService';
import { planningIntervals } from '../../components/jira/PlanningIntervalDropdown';

export const FetchParentJiraTicket: React.FC = () => {
  const [ticketKey, setTicketKey] = useState<string>('');
  const [issueData, setIssueData] = useState<JiraTicket | undefined>(undefined);
  const [error, setError] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const isValidParentTicket = () => {
      if (isNotNullOrUndefined(issueData)) {
        if (issueData.issueType === 'Feature') {
          return true;
        } else {
          setError(`Issue type must be "Feature", found "${issueData.issueType}" instead`);
        }
      }
      return false;
    };

    if (isStringNotBlank(error)) {
      dispatch(updateParentJiraTicket({ parentJiraTicket: undefined }));
    } else if (isNotNullOrUndefined(issueData) && isValidParentTicket()) {
      dispatch(updateParentJiraTicket({ parentJiraTicket: issueData }));
    }
  }, [issueData, error, dispatch]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTicketKey(event.target.value);
    dispatch(updateParentJiraTicket({ parentJiraTicket: undefined }));
  };

  const fetchIssueData = async () => {
    setLoading(true);
    setError(undefined);
    setIssueData(undefined);

    try {
      const parentJiraTicket: JiraTicket = await JiraService.getIssue(ticketKey);
      setIssueData(parentJiraTicket);
    } catch {
      setError('Issue not found or invalid response');
    } finally {
      setLoading(false);
    }
  };

  // Check if the input is blank or not
  const isInputBlank = ticketKey.trim().length === 0;

  return (
    <Box sx={{ maxWidth: 700, margin: '0 auto', padding: 2 }}>
      <TextField
        label={'Parent JIRA Ticket Key (ticket to be linked)'}
        variant="outlined"
        fullWidth
        value={ticketKey}
        onChange={handleInputChange}
        margin="normal"
        placeholder="Enter JIRA ticket key (e.g., PROJ-123)"
        error={isInputBlank}
        helperText={isInputBlank ? 'Parent ticket key is required' : ''}
        inputProps={{
          'data-testid': `parent-jira-ticket-input`,
        }}
      />

      <Button
        variant="contained"
        color="primary"
        onClick={fetchIssueData}
        disabled={loading || isInputBlank} // Disable the button if input is blank or loading
        fullWidth
        data-testid={'fetch-parent-jira-button'}
      >
        {loading ? <CircularProgress size={24} color="inherit" /> : 'Fetch Issue'}
      </Button>

      {isNotNullOrUndefined(error) && (
        <Alert severity="error" sx={{ marginTop: 2 }}>
          {error}
        </Alert>
      )}

      {isNotNullOrUndefined(issueData) && isNotNullOrUndefined(issueData.key) && (
        <Box sx={{ marginTop: 3 }}>
          <Typography variant="h6">Parent Issue Details</Typography>
          <Typography>
            <strong>Key:</strong> <JiraTicketWithLink jiraNumber={issueData.key} />
          </Typography>
          <Typography sx={{ color: issueData.issueType === 'Feature' ? 'black' : 'red' }}>
            <strong>Issue Type:</strong> {issueData.issueType}
          </Typography>
          <Typography>
            <strong>Status:</strong> {issueData.status}
          </Typography>
          <Typography>
            <strong>Planning Intervals:</strong>{' '}
            {issueData.customfield_11519
              ?.map((plan) => `${planningIntervals[plan] ?? 'NOT FOUND'} (${plan})`)
              .join(', ')}
          </Typography>
          <Typography>
            <strong>Issue ID:</strong> {issueData.id}
            <JBCopyButton
              data-testid="copy-button-issue-id"
              onFailedCopy={() => ({})}
              variant="outlined"
              size="small"
              textToCopy={issueData.id}
            ></JBCopyButton>
          </Typography>
          <Typography>
            <strong>Description:</strong>
            <JBCopyButton
              data-testid="copy-button-description"
              onFailedCopy={() => ({})}
              variant="outlined"
              size="small"
              textToCopy={issueData.description}
            ></JBCopyButton>
          </Typography>
          <Typography>
            <strong>Assigned to:</strong> {`${issueData.assignee?.displayName} (${issueData.assignee?.emailAddress})`}
            <JBCopyButton
              data-testid="copy-button-username"
              onFailedCopy={() => ({})}
              variant="outlined"
              size="small"
              textToCopy={issueData.assignee?.name}
            ></JBCopyButton>
          </Typography>
          <Typography>
            <strong>Summary:</strong> {issueData.summary}
            <JBCopyButton
              data-testid="copy-button-username"
              onFailedCopy={() => ({})}
              variant="outlined"
              size="small"
              textToCopy={issueData.summary}
            ></JBCopyButton>
          </Typography>
        </Box>
      )}
    </Box>
  );
};
