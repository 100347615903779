import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Button, Chip, CircularProgress, Grid2 } from '@mui/material';

import { CLMTeam } from '../../types';
import { isNotNullOrUndefined, isNullOrUndefined, isStringNotBlank } from '../../utils/utils';
import { useJiraProcessTicket } from '../../hooks/useJiraProcessTicket';
import {
  getSubComponentJIRATicketInitialData,
  JiraTicket,
  ProcessingTicketStatus,
  updateJiraTicket,
} from '../../store/jiraStore/jiraReducer';
import { JiraTicketWithLink } from './JiraTicketWithLink';

interface Props {
  rowInfo: { componentName: string; team: CLMTeam };
  shouldCreateTicket: boolean;
  isDryRun: boolean;
  jiraTicket: JiraTicket;
}

const getChipColor = (
  newTicketStatus: ProcessingTicketStatus,
): 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' => {
  if (newTicketStatus.includes('ERROR')) {
    return 'error';
  }
  if (newTicketStatus === 'DONE') {
    return 'success';
  }
  if (newTicketStatus === 'NOT_CREATED') {
    return 'info';
  }
  return 'warning';
};

const getButtonText = (newTicketStatus: ProcessingTicketStatus): string | undefined => {
  if (newTicketStatus === 'DONE') {
    return undefined;
  }
  if (newTicketStatus === 'NOT_CREATED') {
    return 'Create';
  }
  return 'Retry';
};

export const NewJiraTicket: React.FC<Props> = ({ rowInfo, shouldCreateTicket, isDryRun, jiraTicket }) => {
  const { componentName } = rowInfo;
  const dispatch = useDispatch();
  const ticket = useJiraProcessTicket(componentName, isDryRun, jiraTicket);

  const handleTicketNextAction = useCallback(async () => {
    if (isNullOrUndefined(ticket)) {
      return;
    }

    const newTicket = {
      ...ticket,
    };

    if (shouldCreateTicket && ticket.processingTicketStatus !== 'DONE') {
      newTicket.processingInProgress = true;
      setTimeout(() => dispatch(updateJiraTicket({ subcomponentName: componentName, jiraPayload: newTicket })), 0);
    } else if (newTicket.processingInProgress) {
      newTicket.processingInProgress = false;
      setTimeout(() => dispatch(updateJiraTicket({ subcomponentName: componentName, jiraPayload: newTicket })), 0);
    }
  }, [shouldCreateTicket, componentName, dispatch, ticket]);

  useEffect(() => {
    if (isNullOrUndefined(ticket)) {
      setTimeout(
        () =>
          dispatch(
            updateJiraTicket({
              subcomponentName: componentName,
              jiraPayload: getSubComponentJIRATicketInitialData(componentName),
            }),
          ),
        0,
      );
    }
  }, [ticket, componentName, dispatch]);

  return (
    <Grid2 container direction="row" alignItems="center" spacing={1} wrap="nowrap">
      {isNotNullOrUndefined(ticket) && isNotNullOrUndefined(ticket?.ticket?.key) && (
        <Grid2>
          <JiraTicketWithLink jiraNumber={ticket.ticket?.key ?? ''} />
        </Grid2>
      )}
      {isNotNullOrUndefined(ticket) && (
        <Grid2>
          <Chip
            label={ticket.processingTicketStatus}
            color={getChipColor(ticket.processingTicketStatus)}
            size="small"
            style={{ marginLeft: '8px' }}
            data-testid={`component-new-jira-status-chip-${rowInfo.componentName}`}
          />
        </Grid2>
      )}
      {isNotNullOrUndefined(ticket) && isStringNotBlank(getButtonText(ticket.processingTicketStatus)) && (
        <Grid2>
          <Button
            variant="contained"
            color="primary"
            disabled={!shouldCreateTicket || ticket.processingInProgress}
            onClick={handleTicketNextAction}
            data-testid={`process-component-ticket-button-${componentName}`}
          >
            {ticket.processingInProgress ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              getButtonText(ticket.processingTicketStatus)
            )}
          </Button>
        </Grid2>
      )}
    </Grid2>
  );
};
