import React, { useState, ChangeEvent } from 'react';

import { Box, Divider, Grid2, Typography, Tabs, Tab } from '@mui/material';

import { FetchParentJiraTicket } from '../components/jira/FetchParentJiraTicket';
import { useCLMComponentsConfig } from '../hooks/useCLMComponentsConfig';
import { Loader } from '../components/Loader';
import { JiraChildTicketsView } from '../components/jira/JiraChildTicketsView';
import { JiraProjectsView } from '../components/jira/JiraProjectsView';
import { JiraIssueLinksView } from '../components/jira/JiraIssueLinksView';
import { JiraFixVersionsView } from '../components/jira/JiraFixVersionsView';

const JiraCrudPage = () => {
  const { data: componentConfig, isLoading } = useCLMComponentsConfig();
  const [selectedTab, setSelectedTab] = useState<number>(0);

  const handleTabChange = (event: ChangeEvent<unknown>, newValue: number) => {
    setSelectedTab(newValue);
  };

  return (
    <Grid2 container direction="column" alignItems="center">
      <Box
        sx={{
          padding: '2.5rem',
          gap: '.5rem',
          width: '80%',
          marginBlock: 2,
          height: 300,
        }}
      >
        <Typography variant="h4" gutterBottom data-testid={'jira-crud-title'}>
          CLM JIRA Issue Support
        </Typography>
        <Tabs value={selectedTab} onChange={handleTabChange} aria-label="JIRA tabs">
          <Tab label="JIRA Issue Creation" />
          <Tab label="JIRA Projects" />
          <Tab label="JIRA Issue Links" />
          <Tab label="JIRA Fix Versions" />
        </Tabs>
        <Divider sx={{ marginTop: '20px' }} />
        {selectedTab === 0 && (
          <>
            <FetchParentJiraTicket />
            <Typography variant="h4" gutterBottom>
              Child JIRA Issues to be Created
            </Typography>
            {isLoading ? <Loader /> : <JiraChildTicketsView clmComponentConfig={componentConfig} />}
          </>
        )}
        {selectedTab === 1 && <JiraProjectsView />}
        {selectedTab === 2 && <JiraIssueLinksView />}
        {selectedTab === 3 && <JiraFixVersionsView />}
      </Box>
    </Grid2>
  );
};

export default JiraCrudPage;
