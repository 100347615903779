import React from 'react';

interface Props {
  jiraNumber: string;
}

export const JiraTicketWithLink: React.FC<Props> = ({ jiraNumber }) => {
  return (
    <a href={`https://jira.sehlat.io/browse/${jiraNumber}`} target="_blank" rel="noreferrer">
      {jiraNumber}
    </a>
  );
};
