import React, { SyntheticEvent, useState } from 'react';

import { useParams } from '@tanstack/react-router';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Divider, Grid2, Tab } from '@mui/material';

import { ComponentConfig } from '../components/ComponentConfig';
import { ComponentHealth } from '../components/ComponentHealth';
import ComponentInfo from '../components/ComponentInfo';
import { RefetchCountdown } from '../components/RefetchCountdown';
import { SwaggerURLTable } from '../components/SwaggerURLTable';
import { useCLMComponentsConfig } from '../hooks/useCLMComponentsConfig';
import { useCLMSubcomponentHealth } from '../hooks/useCLMSubcomponentHealth';
import { useCLMSubcomponentInfo } from '../hooks/useCLMSubcomponentInfo';
import { useValidation } from '../hooks/useValidation';
import { getSubcomponent, isNotNullOrUndefined } from '../utils/utils';

function DrilldownPage() {
  const { subcomponent } = useParams({ from: '/info/component/$subcomponent' });
  const [value, setValue] = useState('1');

  const { data: componentConfig, isLoading } = useCLMComponentsConfig();
  useCLMSubcomponentHealth(componentConfig?.environmentName, componentConfig?.components);
  useCLMSubcomponentInfo(componentConfig?.environmentName, componentConfig?.components);
  useValidation({ components: componentConfig?.components });
  const clmComponent = getSubcomponent({ config: componentConfig, subcomponentName: subcomponent });

  const handleChange = (_: SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'end',
          padding: '1rem',
        }}
      >
        <RefetchCountdown />
      </Box>
      <Divider />
      <Grid2 container direction="column" alignItems="center">
        <Box
          sx={{
            padding: '2.5rem',
            gap: '.5rem',
            width: '80%',
            backgroundColor: '#EFEEE5',
            marginBlock: 2,
            height: 300,
          }}
        >
          {isLoading ? <div>Loading...</div> : null}
          {isNotNullOrUndefined(clmComponent) ? (
            <ComponentConfig component={clmComponent?.component} subcomponent={clmComponent.subcomponent} />
          ) : null}
        </Box>
        <Box
          sx={{
            padding: '1rem',
            gap: '.5rem',
            backgroundColor: '#EFEEE5',
            width: '80%',
          }}
        >
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'black' }}>
              <TabList onChange={handleChange}>
                <Tab label="Infos" value="1" />
                {isNotNullOrUndefined(clmComponent) && clmComponent.subcomponent.swaggerUrls ? (
                  <Tab label="Swagger URLs" value="2" color="#1A2C95" />
                ) : null}
              </TabList>
            </Box>
            <TabPanel value="1">
              {isNotNullOrUndefined(clmComponent) ? (
                <>
                  <ComponentHealth
                    isLoading={isLoading}
                    component={clmComponent?.component}
                    subcomponent={clmComponent?.subcomponent}
                  />
                  <ComponentInfo component={clmComponent.component} subcomponent={clmComponent.subcomponent} />
                </>
              ) : null}
            </TabPanel>
            {isNotNullOrUndefined(clmComponent) && clmComponent.subcomponent.swaggerUrls ? (
              <TabPanel value="2">
                <SwaggerURLTable subcomponent={clmComponent.subcomponent} />
              </TabPanel>
            ) : null}
          </TabContext>
        </Box>
      </Grid2>
    </Box>
  );
}

export default DrilldownPage;
