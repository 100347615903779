import React, { useCallback, useState } from 'react';

import {
  Alert,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import fileDownload from 'js-file-download';

import jsonExample from '../assets/jira/jira-tickets-example.json';
import JiraService from '../services/JiraService';
import { JiraTicketWithLink } from '../components/jira/JiraTicketWithLink';
import { PlanningIntervalDropdown } from '../components/jira/PlanningIntervalDropdown';
import { JiraTicket } from '../store/jiraStore/jiraReducer';
import { isNotNullOrUndefined, isNullOrUndefined } from '../utils/utils';

interface JiraTicketCreationResult {
  originalKey: string;
  createdKey: string;
  status: 'pending' | 'success' | 'error' | 'skipped';
  linkStatus?: 'pending' | 'success' | 'error';
  error?: string;
  linkError?: string;
  hasLinks: boolean;
}

interface JiraTicketsJson {
  credentials: {
    username: string;
    password: string;
  };
  genericFields: {
    reporter: {
      name: string;
    };
  };
  tickets: Array<
    Record<
      string,
      {
        fields: {
          key?: string;
          summary: string;
          description: string;
          issuetype: {
            name: string;
          };
          project: {
            key: string;
          };
          labels?: string[];
        };
        links?: Array<{
          type: {
            name: string;
          };
          outwardIssue: {
            key: string;
          };
        }>;
      }
    >
  >;
}

const JiraTicketCreator: React.FC = () => {
  const [jsonData, setJsonData] = useState<JiraTicketsJson | null>(null);
  const [isCreating, setIsCreating] = useState(false);
  const [results, setResults] = useState<JiraTicketCreationResult[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [selectedPlanningIntervals, setSelectedPlanningIntervals] = useState<string[]>([]);
  const [isDryRun, setIsDryRun] = useState<boolean>(true);

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const json = JSON.parse(e.target?.result as string);

          if (isNullOrUndefined(json?.tickets) || json?.tickets.length === 0) {
            setJsonData(null);
            setError('No tickets found');
          } else if (isNotNullOrUndefined(json)) {
            setJsonData(json);
            setError(null);
          }
        } catch (err) {
          setError('Invalid JSON file');
          // eslint-disable-next-line no-console
          console.error('Error parsing JSON:', err);
        }
      };
      reader.readAsText(file, 'ascii');
    }
  };

  const handleDropdownChange = (selectedValues: string[]) => {
    setSelectedPlanningIntervals(selectedValues);
  };

  const handleIsDryRunChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsDryRun(event.target.checked);
  };

  const createTickets = useCallback(async () => {
    if (!jsonData) return;

    setIsCreating(true);
    setResults([]);
    const createdKeys = new Map<string, string>();
    const availableKeys = new Set<string>();

    try {
      // First, collect all available keys and pre-existing tickets
      for (const ticketGroup of jsonData.tickets) {
        Object.entries(ticketGroup).forEach(([key, data]) => {
          availableKeys.add(key);
          // If ticket already has a key, add it to createdKeys
          if (data.fields.key) {
            createdKeys.set(key, data.fields.key);
          }
        });
      }

      // Create tickets
      for (const ticketGroup of jsonData.tickets) {
        for (const [originalKey, ticketData] of Object.entries(ticketGroup)) {
          try {
            // Skip creation if ticket already has a key
            const providedKey = ticketData.fields.key;
            if (isNotNullOrUndefined(providedKey)) {
              setResults((prev) => [
                ...prev,
                {
                  originalKey,
                  createdKey: providedKey,
                  status: 'skipped',
                  hasLinks: Boolean(ticketData.links?.length),
                  linkStatus: ticketData.links?.length ? 'pending' : undefined,
                },
              ]);
              continue;
            }

            const jiraTicket: JiraTicket = {
              summary: ticketData.fields.summary,
              description: ticketData.fields.description,
              issueType: ticketData.fields.issuetype.name,
              projectKey: ticketData.fields.project.key,
              labels: ticketData.fields.labels,
              reporter: jsonData.genericFields.reporter.name,
              customfield_11519: selectedPlanningIntervals,
            };

            let createdKey = ticketData.fields.project.key + '-' + (Math.floor(Math.random() * 9000) + 1000);
            if (!isDryRun) {
              createdKey = await JiraService.createIssue(jiraTicket);
            }
            // eslint-disable-next-line no-console
            console.log(`Created ticket for ${originalKey}`, jiraTicket);
            createdKeys.set(originalKey, createdKey);

            // Check if any linked tickets don't exist
            const hasInvalidLinks = ticketData.links?.some((link) => !availableKeys.has(link.outwardIssue.key));

            setResults((prev) => [
              ...prev,
              {
                originalKey,
                createdKey,
                status: 'success',
                hasLinks: Boolean(ticketData.links?.length),
                linkStatus: hasInvalidLinks ? 'error' : ticketData.links?.length ? 'pending' : undefined,
                linkError: hasInvalidLinks ? 'Invalid link: referenced ticket does not exist' : undefined,
              },
            ]);
          } catch {
            setResults((prev) => [
              ...prev,
              {
                originalKey,
                createdKey: '',
                status: 'error',
                error: 'Failed to create ticket',
                hasLinks: Boolean(ticketData.links?.length),
              },
            ]);
          }
        }
      }

      // Create links (only for valid links)
      for (const ticketGroup of jsonData.tickets) {
        for (const [originalKey, ticketData] of Object.entries(ticketGroup)) {
          if (ticketData.links) {
            for (const link of ticketData.links) {
              // Skip if the linked ticket doesn't exist
              if (!availableKeys.has(link.outwardIssue.key)) {
                continue;
              }

              const inwardKey = createdKeys.get(originalKey);
              const outwardKey = createdKeys.get(link.outwardIssue.key);

              if (inwardKey && outwardKey) {
                try {
                  const linkIssuesData = {
                    type: link.type,
                    inwardIssue: { key: inwardKey },
                    outwardIssue: { key: outwardKey },
                  };
                  if (!isDryRun) {
                    await JiraService.linkIssues(linkIssuesData);
                  }
                  // eslint-disable-next-line no-console
                  console.log(
                    `Creating link between tickets ${originalKey} and ${link.outwardIssue.key}`,
                    linkIssuesData,
                  );
                  setResults((prev) =>
                    prev.map((result) =>
                      result.originalKey === originalKey ? { ...result, linkStatus: 'success' } : result,
                    ),
                  );
                } catch (err) {
                  // eslint-disable-next-line no-console
                  console.error(`Failed to create link between ${inwardKey} and ${outwardKey}:`, err);
                  setResults((prev) =>
                    prev.map((result) =>
                      result.originalKey === originalKey
                        ? { ...result, linkStatus: 'error', linkError: 'Failed to create link' }
                        : result,
                    ),
                  );
                }
              }
            }
          }
        }
      }
    } catch (err) {
      setError('Failed to process tickets');
      // eslint-disable-next-line no-console
      console.error('Error creating tickets:', err);
    } finally {
      setIsCreating(false);
    }
  }, [jsonData, selectedPlanningIntervals, isDryRun]);

  const handleExampleJsonDownload = () => {
    fileDownload(JSON.stringify(jsonExample, null, 2), 'jira-tickets-example.json');
  };

  return (
    <Box sx={{ maxWidth: 800, margin: '0 auto', padding: 2, marginTop: 5 }}>
      <Typography variant="h5" gutterBottom>
        JIRA Ticket Creator
      </Typography>
      <Box sx={{ marginBottom: 2 }}>
        <input
          accept=".json"
          style={{ display: 'none' }}
          id="raised-button-file"
          type="file"
          onChange={handleFileUpload}
          data-testid="json-file-input"
        />
        <label htmlFor="raised-button-file">
          <Button variant="contained" component="span">
            Load JSON File
          </Button>
        </label>
      </Box>
      {error && (
        <Alert severity="error" sx={{ marginBottom: 2 }}>
          {error}
        </Alert>
      )}
      {jsonData && (
        <Box sx={{ marginBottom: 2, marginTop: 10 }}>
          <Typography>Select inputs and then click create:</Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={isDryRun}
                onChange={handleIsDryRunChange}
                inputProps={
                  {
                    'data-testid': 'dry-run-checkbox',
                  } as React.InputHTMLAttributes<HTMLInputElement>
                }
              />
            }
            label="Dry Run"
          />
          <PlanningIntervalDropdown values={selectedPlanningIntervals} onChange={handleDropdownChange} />
          <Button
            variant="contained"
            color="primary"
            onClick={createTickets}
            disabled={isCreating || selectedPlanningIntervals.length === 0}
            sx={{ marginBottom: 2 }}
            data-testid="create-tickets-button"
          >
            {isCreating ? <CircularProgress size={24} /> : 'Create Tickets'}
          </Button>
        </Box>
      )}
      {results.length > 0 && (
        <Paper sx={{ marginTop: 2 }}>
          <List>
            {results.map((result, index) => (
              <ListItem
                key={index}
                sx={{
                  borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
                  '&:last-child': {
                    borderBottom: 'none',
                  },
                }}
              >
                <ListItemText
                  primary={
                    <>
                      {result.originalKey} →{' '}
                      {result.status === 'success' || result.status === 'skipped' ? (
                        <JiraTicketWithLink jiraNumber={result.createdKey} />
                      ) : (
                        'Creation failed'
                      )}
                    </>
                  }
                  secondary={
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                      <div data-testid={`creation-status-${result.status}`}>
                        {result.status === 'error' ? (
                          <span style={{ color: '#d32f2f' }}>{result.error}</span>
                        ) : result.status === 'skipped' ? (
                          <span style={{ color: 'green' }}>Creation skipped because key was provided</span>
                        ) : (
                          <span style={{ color: 'green' }}>Created successfully</span>
                        )}
                      </div>
                      <div data-testid={`link-status-${result.linkStatus ?? 'none'}`}>
                        {result.hasLinks ? (
                          result.linkStatus === 'error' ? (
                            <span style={{ color: '#d32f2f' }}>Link failed: {result.linkError}</span>
                          ) : result.linkStatus === 'success' ? (
                            <span style={{ color: 'green' }}>Linked successfully</span>
                          ) : (
                            <span style={{ color: 'orange' }}>No links created</span>
                          )
                        ) : (
                          <span style={{ color: 'green' }}>No links required</span>
                        )}
                      </div>
                    </Box>
                  }
                  sx={{
                    '& .MuiListItemText-primary': {
                      color: result.status === 'error' ? '#d32f2f' : 'inherit',
                      marginBottom: '8px',
                    },
                    '& .MuiListItemText-secondary': {
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '4px',
                      color: 'text.primary',
                    },
                  }}
                />
              </ListItem>
            ))}
          </List>
        </Paper>
      )}

      {/* Example Section */}
      <Box sx={{ marginTop: 4, padding: 2, bgcolor: '#f5f5f5', borderRadius: 1 }}>
        <Typography variant="h6" gutterBottom>
          Example
        </Typography>
        <Typography variant="body2" sx={{ marginBottom: 2 }}>
          The JSON file should follow a specific structure.
          <ul>
            <li>
              The &quot;reporter.name&quot; field should be your UUID, which can be found at&nbsp;
              <a href="https://jira.sehlat.io/secure/ViewProfile.jspa" target="_blank" rel="noopener noreferrer">
                JIRA Profile Page
              </a>{' '}
              under &quot;username&quot;.
            </li>
            <li>
              If the parent ticket already exists and you would like to avoid creating it again, just add the ticket
              number to &quot;fields.key&quot;.
            </li>
          </ul>
        </Typography>
        <Button variant="outlined" component="a" onClick={handleExampleJsonDownload} startIcon={<DownloadIcon />}>
          Download Example JSON
        </Button>
      </Box>
    </Box>
  );
};

export default JiraTicketCreator;
