import { useSelector } from 'react-redux';

import { JiraState, JiraTicket, SubComponentJIRATicket } from './jiraReducer';
import { GlobalState } from '../globalStateTypes';

const selectJIRATicketBySubComponentName = (
  state: JiraState,
  subcomponentName: string,
): SubComponentJIRATicket | undefined => {
  return state.subComponentJIRATickets[subcomponentName];
};

export const useSubComponentJiraTicketState = (subcomponentName: string) => {
  return useSelector((state: GlobalState) => selectJIRATicketBySubComponentName(state.jira, subcomponentName));
};

const selectParentJIRATicket = (state: JiraState): JiraTicket | undefined => {
  return state.parentJiraTicket;
};

export const useParentJiraTicket = () => {
  return useSelector((state: GlobalState) => selectParentJIRATicket(state.jira));
};

const selectJIRATickets = (state: JiraState): Record<string, SubComponentJIRATicket> => {
  return state.subComponentJIRATickets;
};

export const useJiraTicketState = () => {
  return useSelector((state: GlobalState) => selectJIRATickets(state.jira));
};
